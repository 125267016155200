// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table {
    border-collapse: collapse;
    border: 2px solid rgb(140 140 140);
    font-family: sans-serif;
    font-size: 0.8rem;
    letter-spacing: 1px;
}

caption {
    caption-side: bottom;
    padding: 10px;
    font-weight: bold;
}

/*thead, tfoot {*/
/*    background-color: rgb(228 240 245);*/
/*}*/

th, td {
    border: 1px solid rgb(160 160 160);
    padding: 8px 10px;
    text-align: center;
}

td {
    text-align: center;
}

/*tbody > tr:nth-of-type(even) {*/
/*    background-color: rgb(237 238 242);*/
/*}*/

tfoot th {
    text-align: right;
}

tfoot td {
    font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/components/Main/Table/style.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,kCAAkC;IAClC,uBAAuB;IACvB,iBAAiB;IACjB,mBAAmB;AACvB;;AAEA;IACI,oBAAoB;IACpB,aAAa;IACb,iBAAiB;AACrB;;AAEA,iBAAiB;AACjB,0CAA0C;AAC1C,IAAI;;AAEJ;IACI,kCAAkC;IAClC,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;AACtB;;AAEA,iCAAiC;AACjC,0CAA0C;AAC1C,IAAI;;AAEJ;IACI,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":["table {\r\n    border-collapse: collapse;\r\n    border: 2px solid rgb(140 140 140);\r\n    font-family: sans-serif;\r\n    font-size: 0.8rem;\r\n    letter-spacing: 1px;\r\n}\r\n\r\ncaption {\r\n    caption-side: bottom;\r\n    padding: 10px;\r\n    font-weight: bold;\r\n}\r\n\r\n/*thead, tfoot {*/\r\n/*    background-color: rgb(228 240 245);*/\r\n/*}*/\r\n\r\nth, td {\r\n    border: 1px solid rgb(160 160 160);\r\n    padding: 8px 10px;\r\n    text-align: center;\r\n}\r\n\r\ntd {\r\n    text-align: center;\r\n}\r\n\r\n/*tbody > tr:nth-of-type(even) {*/\r\n/*    background-color: rgb(237 238 242);*/\r\n/*}*/\r\n\r\ntfoot th {\r\n    text-align: right;\r\n}\r\n\r\ntfoot td {\r\n    font-weight: bold;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
