import { observer } from "mobx-react";

import s from "./style.module.css";
import {RatingPlayer} from "./RatingPlayer.js";
import {Table} from "../../../components/Main/Table/Table.js";
import {useContext, useEffect, useState} from "react";
import {CS2RatingStoreContext, UserStoreContext} from "../../../App.js";
import UserStore from "../../../modules/Squad/UserStore.js";
import {Button, Modal} from "react-bootstrap";
import RainbowLine from "../../../components/Universe/Other/RainbowLine.jsx";



export const RatingCSPage = observer(() => {
    const [isOpen, setOpen] = useState(false);
    const [check, setCheck] = useState(false);

    const CS2RatingStore = useContext(CS2RatingStoreContext);
    const UserStore = useContext(UserStoreContext);

    useEffect(() => {
        async function fetchData() {
            await CS2RatingStore.getRatingPlayers();
        }

        fetchData();
    }, []);

    // const players = [
    //     {
    //         nickname: "Excels1or",
    //         rating: 2.21
    //     },
    //     {
    //         nickname: "flyama",
    //         rating: 4.35
    //     },
    //     {
    //         nickname: "nasos",
    //         rating: 6.47
    //     },
    //     {
    //         nickname: "Anton",
    //         rating: 1.43
    //     }
    // ];
    //
    // const isAdmin = true;

    const toggleModal = () => {
        setOpen(!isOpen);
    }

    const saveData = () => {
        CS2RatingStore.addRatingPlayer();
    }

    const rows = [];

    CS2RatingStore.cs2players.map((player, index) => {
        rows.push({values: [index + 1, player.nickname, player.rating]});
    });

    const onChangeNickname = (event) => {
        CS2RatingStore.nickname = event.target.value;
    }

    const onChangeKills = (event) => {
        CS2RatingStore.kills = Number(event.target.value);
    }

    const onChangeDeaths = (event) => {
        CS2RatingStore.deaths = Number(event.target.value);
    }

    const onChangeAssists = (event) => {
        CS2RatingStore.assists = Number(event.target.value);
    }

    const onChangeHeadshotPercent = (event) => {
        CS2RatingStore.headshotPercent = Number(event.target.value);
    }

    const onChangeWins = (event) => {
        CS2RatingStore.wins = Number(event.target.value);
    }

    const onChangeLosses = (event) => {
        CS2RatingStore.losses = Number(event.target.value);
    }

    const onChangeWinRounds = (event) => {
        CS2RatingStore.winRounds = Number(event.target.value);
    }

    const onChangeLossRounds = (event) => {
        CS2RatingStore.lossRounds = Number(event.target.value);
    }

    const onChangeDamage = (event) => {
        CS2RatingStore.damage = Number(event.target.value);
    }

    const onChangeIsWin = () => {
        let newCheck = !check;
        setCheck(newCheck);
        CS2RatingStore.isWin = newCheck;
    }


    return (
        <div className={s.wrapper}>
            {/*<h1 className={s.pageTitle}>Коробки</h1>*/}

            {/*<div className={s.playersList}>*/}
            {/*    {players.map((player, index) => {*/}
            {/*        return (*/}
            {/*            <RatingPlayer key={index} index={index} player={player} isAdmin={isAdmin}  />*/}
            {/*        );*/}
            {/*    })}*/}
            {/*</div>*/}

            {UserStore.userData.accessLevel >= 4 &&
                <div>
                    Button
                </div>
            }

            {
                <div>
                    <Button className="button" variant="outline-secondary" onClick={toggleModal}>
                        Register
                    </Button>
                </div>
            }


            <Table columns={["Place", "Nickname", "Rating"]} rows={rows} />

            <Modal
                show={isOpen}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header>
                    <Modal.Title>
                        Внесение данных
                    </Modal.Title>
                </Modal.Header>
                <RainbowLine />
                <Modal.Body>
                    <div className={s.inputs}>
                        <input placeholder="Nickname" onChange={onChangeNickname} />
                        <input placeholder="Kills" onChange={onChangeKills} />
                        <input placeholder="Deaths" onChange={onChangeDeaths} />
                        <input placeholder="Assists" onChange={onChangeAssists} />
                        <input placeholder="Headshot Percent" onChange={onChangeHeadshotPercent} />
                        <input placeholder="Wins" onChange={onChangeWins} />
                        <input placeholder="Losses" onChange={onChangeLosses} />
                        <input placeholder="Win Rounds" onChange={onChangeWinRounds} />
                        <input placeholder="Loss Rounds" onChange={onChangeLossRounds} />
                        <input placeholder="Damage" onChange={onChangeDamage} />
                        Is Win <input type="checkbox" placeholder="Is Win" checked={check} onChange={onChangeIsWin} />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div>
                        <button onClick={toggleModal}>
                            Закрыть
                        </button>
                        <button onClick={saveData}>
                            Внести
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    );
});