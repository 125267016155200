import React from "react";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { observer } from "mobx-react";


// PAGES
import NotFoundPage from "../../pages/Utils/NotFoundPage.jsx";
import TechnicalWorks from "../../pages/Utils/TechnicalWorks.jsx"

// STORES
import WB from "../../pages/Other/WB.jsx";

import { WildberriesStoreContext } from "../../App.js";



const WildberriesSection = observer(() => {
    // const User = useContext(UserStoreContext);
    //
    // useEffect(() => {
    //     User.updateUserData();
    // }, [User]);

    return (
        <>
            <Routes>
                {/*<Route path="*" element={<TechnicalWorks />} />*/}

                <Route path="/wb" element={<WB />} />
            </Routes>
        </>
    );
});

export default WildberriesSection;