import {useEffect, useState} from "react";
import axios from "axios";
import s from "./style.module.css";
import {Chart} from "../../../../components/Main/Chart/Chart.jsx";

export const EnergyPage = () => {
    const [timeout, setTimeout] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [energy, setEnergy] = useState(0);
    const [date, setDate] = useState(0);
    const [energyText, setEnergyText] = useState("");
    const [dayChart, setDayChart] = useState([]);

    useEffect(() => {
        start();

        return () => {
            clearInterval(timeout);
        }
    }, []);

    const intervalFunc = async () => {
        const response = await axios.get("http://default-squad.ru:8542/api/misc/gtnh-get-energy");

        const {energyText, dayChart} = response.data;

        const last = dayChart.values[dayChart.values.length - 1];

        setEnergy(last.value);
        setDate(last.time);
        setEnergyText(energyText);
        setDayChart(dayChart);
        setIsLoaded(true);

        return () => {
            clearInterval(timeout);
        }
    }

    const start = async () => {
        await intervalFunc();

        setInterval(async () => {
            await intervalFunc();
        }, 10000);
    }

    return (
        <div className={s.wrapper}>
            <div className={`${s.mainText} green-shimmering-text`}>
                {
                    isLoaded ? `${energy} EU` : "Загрузка..."
                }
            </div>
            {
                isLoaded &&
                <>
                    <div>
                        {energyText}
                    </div>
                    <div className={s.secondText}>
                        Информация на {new Date(date).toLocaleString()}
                    </div>
                </>
            }

            <div className={s.chart}>
                График
                <Chart data={dayChart} />
            </div>
        </div>
    );
}