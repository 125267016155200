import {makeAutoObservable, runInAction} from "mobx";
import axios from "axios";
import MainStore from "../../../modules/Main/MainStore.js";
import NotificationsCenter from "../../../modules/Main/NotificationsCenter.js";



class CS2RatingStore {
    cs2players = [];

    nickname = "";
    kills = 0;
    deaths = 0;
    assists = 0;
    headshotPercent = 0;
    wins = 0;
    losses = 0;
    winRounds = 0;
    lossRounds = 0;
    damage = 0;
    isWin = false;

    constructor() {
        makeAutoObservable(this);
    }

    addRatingPlayer = async () => {
        // let response = (await axios.post(`${MainStore.SERVER_ADDRESS}/api/misc/addRatingPlayer`, {
        //     nickname: this.nickname,
        //     kills: this.kills, deaths: this.deaths,
        //     assists: this.assists, headshotPercent: this.headshotPercent,
        //     wins: this.wins, losses: this.losses,
        //     winRounds: this.winRounds, lossRounds: this.lossRounds,
        //     damage: this.damage,
        //     isWin: this.isWin
        // }, {
        //     withCredentials: true
        // })).data;
        //
        // NotificationsCenter.ShowSuccess(response.message);

        // Всё работает, нужно просто расскоментить.

        // Этот лог просто для того, чтобы любой другой не смог добавить данные
        console.log("Kek");
    }

    getRatingPlayers = async () => {
        let players = (await axios.get(`${MainStore.SERVER_ADDRESS}/api/misc/getRatingPlayers`)).data;

        runInAction(() => {
            this.cs2players = players;
        });
    }
}

export default new CS2RatingStore();