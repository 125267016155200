// COMPONENTS
// import PublicHeader from "../../components/Main/PublicHeader.jsx";
// import Footer from "../../components/Index/Footer.jsx";

// MODULES
// import UniverseLoginForm from "../../modules/Universe/Auth/UniverseLoginForm.jsx";

import s from "./style.module.css";



export const UniverseIndex = () => {
    return (
        <div className={s.wrapper}>
            В разработке
        </div>
    );
};