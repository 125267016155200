// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_wrapper__2\\+vWK {
    display: flex;
    justify-content: center;
    padding: 2em;
}`, "",{"version":3,"sources":["webpack://./src/pages/Universe/Index/style.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,YAAY;AAChB","sourcesContent":[".wrapper {\r\n    display: flex;\r\n    justify-content: center;\r\n    padding: 2em;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `style_wrapper__2+vWK`
};
export default ___CSS_LOADER_EXPORT___;
