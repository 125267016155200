import s from "./style.module.css";

export const RatingPlayer = ({index, player, isAdmin}) => {
    const playerClick = (event) => {
        // open modal window
        console.log(event.target);
    }

    return (
        <div onClick={isAdmin ? playerClick : ""} className={s.player}>
            <span>{index + 1}</span>
            <span>{player.nickname}</span>
            <span>{player.rating}</span>
        </div>
    );
}