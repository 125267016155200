import React from "react";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { observer } from "mobx-react";


// PAGES
import NotFoundPage from "../../pages/Utils/NotFoundPage.jsx";
import TechnicalWorks from "../../pages/Utils/TechnicalWorks.jsx";
import DefaultCoin from "../../components/Squad/Fun/DefaultCoin.jsx";
import Project416 from "../../pages/Games/Project416/Project416.jsx";
import Project416Ok from "../../pages/Games/Project416/Project416Ok.jsx";

// COMPONENTS

// STORES



const GamesSection = observer(() => {
    return (
        <>
            <Routes>
                {/*<Route path="*" element={<TechnicalWorks />} />*/}

                <Route path="/games/coin" element={<DefaultCoin/>}/>
                <Route path="/games/project-416" element={<Project416/>}/>
                <Route path="/games/project-416/ok" element={<Project416Ok/>}/>
            </Routes>
        </>
    );
});

export default GamesSection;