// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    font-family: ManropeMedium, "Segoe UI", sans-serif;
}

.style_wrapper__C27yt {
    display: grid;
}

.style_boxes__PSow3 {
    display: grid;
    grid-template-columns: .25fr .25fr .25fr .25fr;
    gap: 1em;
    padding: 2em;
}

.style_box__dbFQ7 {
    padding: 2em;
    display: flex;
    flex-direction: column;

    border: gainsboro 2px solid;
}

.style_boxTitle__1jVze {
    font-size: 1.5em;
    margin-bottom: 1em;
}

.style_boxInfo__yFmpi {
    display: flex;
    flex-direction: column;
    padding: 2em;
    margin: 2em;

    border: #4d5154 2px dotted;
}

.style_boxTime__BnCQk {
    font-size: .75em;
    color: gray;
}`, "",{"version":3,"sources":["webpack://./src/pages/Other/Info/style.module.css"],"names":[],"mappings":"AAAA;IACI,kDAAkD;AACtD;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,8CAA8C;IAC9C,QAAQ;IACR,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,sBAAsB;;IAEtB,2BAA2B;AAC/B;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,WAAW;;IAEX,0BAA0B;AAC9B;;AAEA;IACI,gBAAgB;IAChB,WAAW;AACf","sourcesContent":["body {\r\n    font-family: ManropeMedium, \"Segoe UI\", sans-serif;\r\n}\r\n\r\n.wrapper {\r\n    display: grid;\r\n}\r\n\r\n.boxes {\r\n    display: grid;\r\n    grid-template-columns: .25fr .25fr .25fr .25fr;\r\n    gap: 1em;\r\n    padding: 2em;\r\n}\r\n\r\n.box {\r\n    padding: 2em;\r\n    display: flex;\r\n    flex-direction: column;\r\n\r\n    border: gainsboro 2px solid;\r\n}\r\n\r\n.boxTitle {\r\n    font-size: 1.5em;\r\n    margin-bottom: 1em;\r\n}\r\n\r\n.boxInfo {\r\n    display: flex;\r\n    flex-direction: column;\r\n    padding: 2em;\r\n    margin: 2em;\r\n\r\n    border: #4d5154 2px dotted;\r\n}\r\n\r\n.boxTime {\r\n    font-size: .75em;\r\n    color: gray;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `style_wrapper__C27yt`,
	"boxes": `style_boxes__PSow3`,
	"box": `style_box__dbFQ7`,
	"boxTitle": `style_boxTitle__1jVze`,
	"boxInfo": `style_boxInfo__yFmpi`,
	"boxTime": `style_boxTime__BnCQk`
};
export default ___CSS_LOADER_EXPORT___;
