// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*body {*/
/*    justify-content: center;*/
/*}*/

.style_wrapper__Pf0Ho {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.style_mainText__wpDW2 {
    font-size: 3em;
}

.style_secondText__-rXxZ {
    padding: 5px;
    font-size: 1em;
    background: dimgray;
}

.style_chart__mncYr {
    width: 30em;
}`, "",{"version":3,"sources":["webpack://./src/pages/Other/GTNH/GetEnergy/style.module.css"],"names":[],"mappings":"AAAA,SAAS;AACT,+BAA+B;AAC/B,IAAI;;AAEJ;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,YAAY;IACZ,cAAc;IACd,mBAAmB;AACvB;;AAEA;IACI,WAAW;AACf","sourcesContent":["/*body {*/\r\n/*    justify-content: center;*/\r\n/*}*/\r\n\r\n.wrapper {\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n}\r\n\r\n.mainText {\r\n    font-size: 3em;\r\n}\r\n\r\n.secondText {\r\n    padding: 5px;\r\n    font-size: 1em;\r\n    background: dimgray;\r\n}\r\n\r\n.chart {\r\n    width: 30em;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `style_wrapper__Pf0Ho`,
	"mainText": `style_mainText__wpDW2`,
	"secondText": `style_secondText__-rXxZ`,
	"chart": `style_chart__mncYr`
};
export default ___CSS_LOADER_EXPORT___;
