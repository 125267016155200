import { observer } from "mobx-react";

import s from "./style.module.css";




const InfoPage = observer(() => {
    // const WildberriesStore = useContext(WildberriesStoreContext);

    const boxes = [
        {
            name: "Kak ya pokakal ytrom",
            description: "Naslazdenie ot processa perdezh v zhope",
            timestamp: 1700000000000
        },
        {
            name: "GOvNO",
            description: "Коробка какашек",
            timestamp: 1706000000000
        },
        {
            name: "Sssmalll",
            description: "Huiona",
            timestamp: 1700005000000
        },
        {
            name: "Futurem",
            description: "Biznes i po popke)))",
            timestamp: 1740000000000
        },
        {
            name: "VERY LONG BOX",
            description: "Ya dristal, ya dristal, ya dristal, ya dristal, ya dristal, ya dristal, ya dristal, ya dristal, ya dristal, ya dristal, ya dristal, ya dristal, ya dristal, ya dristal, ya dristal, ya dristal, ya dristal",
            timestamp: 1740000000000
        }
    ];

    return (
        <div className={s.wrapper}>
            {/*<h1 className={s.pageTitle}>Коробки</h1>*/}
            <div className={s.boxes}>
                {boxes.map(box => {
                    return (
                        <div className={s.box}>
                            <span className={s.boxTitle}>{box.name}</span>
                            <span>{box.description.length > 45 ? box.description.slice(0, 45) + "..." : box.description} ({box.description.length})</span>
                            <span>{new Date(box.timestamp).toLocaleString()}</span>
                        </div>
                    );
                })}
            </div>
            <hr />
            <div className={s.boxInfo}>
                <span className={s.boxTitle}>{boxes[0].name} <span className={s.boxTime}>{new Date(boxes[0].timestamp).toLocaleString()}</span></span>
                <span>{boxes[0].description}</span>
            </div>
        </div>
    );
});

export default InfoPage;