// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    font-family: ManropeMedium, "Segoe UI", sans-serif;
}

.style_wrapper__S5\\+JJ {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2em;
}

.style_playersList__KPKn3 {
    display: grid;
    grid-template-rows: .25fr .25fr .25fr .25fr;
    gap: 1em;
    padding: 2em;
}

.style_player__W1r8b {
    padding: 2em;
    display: flex;

    border: gainsboro 2px solid;
}

.style_inputs__G7s8d {
    display: flex;
    flex-direction: column;
}`, "",{"version":3,"sources":["webpack://./src/pages/Other/RatingCS/style.module.css"],"names":[],"mappings":"AAAA;IACI,kDAAkD;AACtD;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,2CAA2C;IAC3C,QAAQ;IACR,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,aAAa;;IAEb,2BAA2B;AAC/B;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B","sourcesContent":["body {\r\n    font-family: ManropeMedium, \"Segoe UI\", sans-serif;\r\n}\r\n\r\n.wrapper {\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    padding: 2em;\r\n}\r\n\r\n.playersList {\r\n    display: grid;\r\n    grid-template-rows: .25fr .25fr .25fr .25fr;\r\n    gap: 1em;\r\n    padding: 2em;\r\n}\r\n\r\n.player {\r\n    padding: 2em;\r\n    display: flex;\r\n\r\n    border: gainsboro 2px solid;\r\n}\r\n\r\n.inputs {\r\n    display: flex;\r\n    flex-direction: column;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `style_wrapper__S5+JJ`,
	"playersList": `style_playersList__KPKn3`,
	"player": `style_player__W1r8b`,
	"inputs": `style_inputs__G7s8d`
};
export default ___CSS_LOADER_EXPORT___;
